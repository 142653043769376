import React, { useState } from "react"
import { graphql } from "gatsby"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Div100vh from "react-div-100vh"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/fix.css"
import * as styles from "../css/gallery.module.css"

const GalleryTemplate = ({ data }) => {
  const [index, setIndex] = useState(0)

  const length = data.allFile.edges.length - 1
  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1)
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1)
  const { node } = data.allFile.edges[index]
  const { childImageSharp } = data.allFile.edges[
    (index + 1) % (length + 1)
  ].node

  const text = () => {
    let link = ""

    switch (node.relativeDirectory) {
      case "helmut-lang-editorial":
        link = "https://www.instagram.com/p/BwkH99AHoso/?hl=en"
        break
      case "glow-mouse":
        link = "https://www.instagram.com/p/B4IztNRhqJ3/"
        break
      case "a$ap-rocky-for-prada":
        link =
          "https://pausemag.co.uk/2019/02/asap-rocky-debuts-custom-prada-x-injured-generation/"
        break
      case "mudboy":
        link =
          "https://www.xxlmag.com/rap-music/reviews/2018/10/sheck-wes-mudboy-review/"
        break
      case "nike-europe-ss-19":
        link = "https://www.instagram.com/p/Byp8TG-IPqs/"
        break
      case "helmut-lang-fw-18":
        link = "https://hypebeast.com/2018/7/sheck-wes-helmut-lang-fall-2018"
        break
      case "ice-cream-holiday-19":
        link = "https://www.bbcicecream.com/blogs/news/icecream-holiday-2019"
        break
      case "ice-cream-x-reebok":
        link =
          "https://www.bbcicecream.com/blogs/news/icecream-fall-2019-collection-look-book"
        break
      case "ssense-noon-goons-editorial":
        link =
          "https://www.ssense.com/en-us/editorial/fashion/girl-meets-noon-goons"
        break
      case "raf-simons-x-templa":
        link =
          "https://www.vogue.com/vogueworld/article/asap-rocky-raf-simons-templa-collaboration-instagram"
        break
      case "heron-preston-x-carhartt-wip":
        link =
          "https://garage.vice.com/en_us/article/ev3kgw/heron-preston-carhartt"
        break
      // case "":
      //   link = "";
      //   break;
      default:
        return (
          <GatsbyImage
            className={styles.text}
            image={getImage(data.file)}
            imgStyle={{ height: "100%" }}
            loading="eager"
            objectFit="contain"
          />
        )
    }

    return (
      <a className={styles.textLink} href={link}>
        <GatsbyImage
          className={styles.text}
          image={getImage(data.file)}
          imgStyle={{ height: "100%" }}
          loading="eager"
          objectFit="contain"
        />
      </a>
    )
  }

  return (
    <Layout back>
      <SEO title={node.relativeDirectory.replace(/-/g, " ").toUpperCase()} />
      <Div100vh>
        <div className={styles.container}>
          <div className={styles.wrap}>
            <div className={styles.imgWrap}>
              <GatsbyImage
                className={styles.gatsbyImg}
                imgClassName={styles.img}
                objectFit="contain"
                loading={index === 0 ? "eager" : "lazy"}
                image={node.childImageSharp.gatsbyImageData}
                key={node.id}
                draggable={false}
              />
              <GatsbyImage
                className={styles.imgNext}
                image={childImageSharp.gatsbyImageData}
              />
            </div>
            {text()}
          </div>
        </div>
      </Div100vh>
      {length > 0 ? (
        <div>
          <Div100vh
            style={{ left: "-10px", justifyContent: "flex-start" }}
            className={styles.button}
            onClick={() => handlePrevious()}
          >
            <FaChevronLeft className={styles.icon} />
          </Div100vh>
          <Div100vh
            style={{ right: "-10px", justifyContent: "flex-end" }}
            className={styles.button}
            onClick={() => handleNext()}
          >
            <FaChevronRight className={styles.icon} />
          </Div100vh>
        </div>
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query gallery($folderName: String!, $textName: String!) {
    allFile(
      sort: { fields: name, order: ASC }
      filter: { relativeDirectory: { eq: $folderName } }
    ) {
      edges {
        node {
          id
          name
          relativeDirectory
          childImageSharp {
            gatsbyImageData(quality: 75, placeholder: BLURRED)
          }
        }
      }
    }
    file(relativePath: { eq: $textName }) {
      childImageSharp {
        gatsbyImageData(height: 17, placeholder: NONE, quality: 100)
      }
    }
  }
`

export default GalleryTemplate
