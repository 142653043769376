// extracted by mini-css-extract-plugin
export var container = "gallery-module--container--2s-Wm";
export var wrap = "gallery-module--wrap--2awvO";
export var imgWrap = "gallery-module--img-wrap--1ArlM";
export var gatsbyImg = "gallery-module--gatsby-img--1YFbF";
export var img = "gallery-module--img---hzRA";
export var imgNext = "gallery-module--imgNext--1dvB9";
export var text = "gallery-module--text--3dcT-";
export var button = "gallery-module--button--3FObk";
export var icon = "gallery-module--icon--3SrBD";
export var textLink = "gallery-module--text-link--2C3wa";